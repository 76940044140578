.infoBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #2979FF;
    border-radius: 4px 4px 0 0;
    height: 40px;
    width: 100%;
  }
  
  .leftInnerContainer {
    flex: 0.5;
    display: flex;
    align-items: center;
    margin-left: 5%;
    color: white;
  }
  
  .rightInnerContainer {
    display: flex;
    flex: 0.5;
    align-items: center;
    justify-content: flex-end;
    margin-right: 5%;
  }
  
  .onlineIcon {
    margin-right: 10px;
    width: 10px;
  }

  .closeIcon {
    cursor: pointer;
    width: 20px;
  }
